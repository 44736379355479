<template lang="pug">
.wf-table.full-box
  DataTable(
    ref="table"
    serverSide
    resource="/collection/list"
    :filterFields="[]"
    :columns="columns"
    :toolbarButtonList="toolbarButtonList"
    :operateButtonList="operateButtonList")
  el-drawer(
    :title="formTitle"
    size="50%"
    :visible.sync="drawer"
    :modal="true"
    append-to-body
    :before-close="handleClose"
    @open="handleDrawerOpen")
    .full-box.drawerForm
      DataForm(
        ref="form"
        v-model="formData"
        buttonFixed
        buttonAlign="right"
        :formFields="formFields"
        :buttonList="buttonList")
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import form from './mixins/form'

export default {
  name: 'MerchantManage',
  mixins: [form],
  data () {
    const actionRights = this.$getPageActions([
      { name: 'add', url: '/file/merchantManage/add' },
      { name: 'edit', url: '/file/merchantManage/edit' },
      { name: 'delete', url: '/file/merchantManage/delete' }
    ])
    return {
      // 表格是否loading
      loading: false,
      // 表格列配置
      columns: [{
        name: 'name',
        label: '账户',
        minWidth: 130
      }, {
        name: 'accountNo',
        label: '账号',
        minWidth: 130
      }, {
        name: 'accountBank',
        label: '开户行',
        minWidth: 130
      }, {
        name: 'collectionNo',
        label: '商户号',
        minWidth: 130
      }, {
        name: 'terminalNo',
        label: '终端号',
        minWidth: 130
      }, {
        name: 'secret',
        label: '密钥',
        minWidth: 130
      }, {
        name: 'orgNo',
        label: '机构号',
        minWidth: 130
      }, {
        name: 'remark',
        label: '备注',
        minWidth: 150
      }],
      toolbarButtonList: actionRights.add ? [{
        label: '新增',
        name: 'add',
        type: 'primary',
        func: () => {
          this.initFormData = {
            remark: '',
            name: '',
            accountBank: '',
            accountNo: ''
          }
          this.$set(this, 'formData', cloneDeep(this.initFormData))
          this.formSubType = 'add'
          this.drawer = true
        }
      }] : [],
      operateButtonList: [{
        label: '修改',
        name: 'edit',
        type: 'text',
        textType: 'primary',
        showabled: actionRights.edit,
        func: ({ data }) => {
          this.initFormData = cloneDeep(data)
          this.formData = cloneDeep(data)
          this.formSubType = 'edit'
          this.drawer = true
        }
      }, {
        label: '删除',
        name: 'delete',
        type: 'text',
        textType: 'danger',
        showabled: actionRights.delete,
        func: this.handleDelete
      }]
    }
  },
  methods: {
    getData () {
      this.$refs.table.reRenderTableData()
    },
    handleDelete ({ data }) {
      return new Promise((resolve, reject) => {
        this.$confirm('是否确认删除', '提示')
          .then(() => {
            this.$httpDelete({
              url: `/collection/delete/${data.id}`
            })
              .then(res => {
                if (!res) {
                  reject(res)
                  return
                }
                this.$message.success('删除成功!')
                this.getData()
                resolve('success')
              })
              .catch(reject)
          })
          .catch(reject)
      })
    },
    handleSuccess () {
      this.handleClose()
      this.getData()
    }
  }
}
</script>
<style lang="sass" scoped>
.wf-table
  display: flex
  flex: 1
  flex-direction: column
  background-color: #eee
  width: 100%
  height: 100%
.drawerForm
  padding: 8px
</style>
