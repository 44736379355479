import cloneDeep from 'lodash/cloneDeep'
export default {
  computed: {
    formTitle () {
      switch (this.formSubType) {
        case 'add':
          return '新增'
        case 'edit':
          return '修改'
        default:
          return '收款账户管理'
      }
    },
    submitApiFuncName () {
      return this.submitApiFuncMap.get(this.formSubType)
    }
  },
  data () {
    const required = [{ required: true, message: '必填' }]
    return {
      // 侧拉表单是否展开
      drawer: false,
      // 表单数据
      formData: {},
      initFormData: {},
      // 表单提交类型
      formSubType: '', // add/edit
      submitApiFuncMap: new Map([
        ['add', 'handleAdd'],
        ['edit', 'handleEdit']
      ]),
      // 表单项配置
      formFields: [{
        name: 'name',
        label: '账户',
        form: {
          tag: 'input',
          rules: required
        }
      }, {
        name: 'accountNo',
        label: '账号',
        form: {
          tag: 'input',
          rules: required
        }
      }, {
        name: 'accountBank',
        label: '开户行',
        form: {
          tag: 'input',
          rules: required
        }
      }, {
        name: 'collectionNo',
        label: '商户号',
        form: {
          tag: 'input',
          rules: required
        }
      }, {
        name: 'terminalNo',
        label: '终端号',
        form: {
          tag: 'input',
          rules: required
        }
      }, {
        name: 'secret',
        label: '密钥',
        form: {
          tag: 'input',
          rules: required
        }
      }, {
        name: 'orgNo',
        label: '机构号',
        form: {
          tag: 'input',
          rules: required
        }
      }, {
        name: 'remark',
        label: '备注',
        form: {
          tag: 'input',
          inputType: 'textarea'
        }
      }],
      buttonList: [{
        label: '提交',
        type: 'primary',
        validate: true,
        func: this.handleSubmit
      }, {
        label: '重置',
        func: this.resetForm
      }]
    }
  },
  methods: {
    resetForm () {
      this.$set(this, 'formData', cloneDeep(this.initFormData))
      this.$nextTick(() => {
        this.$refs.form && this.$refs.form.$refs.dataform.clearValidate()
      })
    },
    handleDrawerOpen () {
      this.$nextTick(() => {
        this.$refs.form && this.$refs.form.$refs.dataform.clearValidate()
      })
    },
    handleClose () {
      this.drawer = false
      this.formSubType = ''
    },
    async handleSubmit () {
      try {
        if (!this.submitApiFuncName) return
        const result = await this[this.submitApiFuncName](this.formData)
        result === 'success' && this.handleSuccess()
        return result
      } catch (e) {
        return e
      }
    },
    handleAdd (formData) {
      return new Promise((resolve, reject) => {
        this.$post({
          url: '/collection/save',
          data: formData
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            resolve('success')
            this.$message.success('新增成功!')
          })
          .catch(reject)
      })
    },
    handleEdit (formData) {
      return new Promise((resolve, reject) => {
        this.$patch({
          url: `/collection/update/${formData.id}`,
          data: formData
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            resolve('success')
            this.$message.success('修改成功!')
          })
          .catch(reject)
      })
    }
  }
}
